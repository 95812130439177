import React from 'react'

import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import { Box } from '@material-ui/core'
import Img from 'gatsby-image'
import { Fade } from 'react-reveal'

import ContainedSection from '../components/ContainedSection'
import Layout from '../components/Layout'
import SEO from '../components/seo'

import media from '../utils/media'

const Promo = () => {
  const data = useStaticQuery(graphql`
    query {
      pageHeaderBackgroundImage: file(
        relativePath: { eq: "promos/header-cover.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      promoImage: file(relativePath: { eq: "promos/promos.png" }) {
        childImageSharp {
          fluid(maxWidth: 420) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout
      pageHeaderBackgroundImage={
        data.pageHeaderBackgroundImage.childImageSharp.fluid.src
      }
      pageTitle="Promo"
    >
      <SEO title="Promo" />
      <S.ContainedSection>
        <Fade bottom>
          <S.PromoImageContainer>
            <Img fluid={data.promoImage.childImageSharp.fluid} />
          </S.PromoImageContainer>
        </Fade>
      </S.ContainedSection>
    </Layout>
  )
}

const S = {
  ContainedSection: styled(ContainedSection)`
    padding-top: 60px;

    ${media.sm`
    padding-top: 60px;
  `}

    ${media.md`
    padding-top: 80px;
  `}
  `,
  PromoImageContainer: styled(Box)`
    margin: auto;
    width: 256px;

    ${media.sm`
			width: 420px;
		`}
  `,
}

export default Promo
